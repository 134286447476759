.main-container {
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* margin-top: -8vh; */
}

.text-container {
    /* position: relative; */
}

.main-container .bio {
    /* display: flex;
    justify-content: space-between; */
    margin-left: 8vw;

}

.main-container .data {
    padding-top: 30vh;
    text-align: left;
    width: 70%;
}

.main-container .author {
    color: white;
    font-size: 8.5rem;
    font-weight: bolder;
    font-family: 'Inter', sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    text-shadow: 6px 6px 0px #3B82F6 ;
}

.main-container .subtitle{
    opacity: 0.64;
    font-size: 1.5rem;
    color: #FFFFFF;
    font-weight: 500;
    margin-top: -4px;
}

.main-container .wavy {
    color: #F55536;
}
.main-container .headshot-img {
    margin-top: 25vh;
    margin-right: 1vw;
}

.main-container .headshot-img img{
    height: 60vh;
    /* width: 100%; */
    border-radius: 8px;
    object-fit: contain;
    /* border: 4px solid #3B82f6; */
    background-color: #3B82F6;
    /* border-image: repeating-linear-gradient( 50deg, #F55536, #F55536 3%, #3B82F6 1%, #3B82F6 8%) 10; */
}

.main-container .headshot-img .img-txt{
    color: #FFFFFF;
    text-align: start;
    opacity: 0.76;
    font-size: 1rem;
    padding: 20px 10px;
    line-height: 1.7;
    letter-spacing: -0.1px;
}



.main-container .skills-container{
    width: 45vw;
    margin-top: 15vh;
}

.main-container .skills-container h3 {
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.8;
    width: fit-content;
    font-size: 2.5rem;
    letter-spacing: -0.51px;
    text-align: left;
    border-bottom: #3B82f6 2px solid;
    border-width: 6px;
    border-radius: 10px;
    border-width: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.main-container .skills-container .skills-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main-container .skills-slider .list {
    margin-right: 30px;
    color: #FFFFFF;
    margin-top: 20px;
    /* justify-content: space-between; */
}

.main-container .skills-container p {
    margin: 0;
}

.main-container .skills-container .express{
    width: 70px;
    height: 70px;
}

.main-container-wrapper .tweets-container {
    margin-left: 8vw;
    margin-top: 15vh;
    width: 90vw;
    color: #ffffff;
    text-align: left;
}

.main-container-wrapper .tweets-container h2{
    
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.8;
    font-size: 2.5rem;
    letter-spacing: -0.51px;
    
    width: fit-content;
    border-bottom: #3B82f6 2px solid;
    /* border-top: #ffffff solid; */
    border-width: 6px;
    border-radius: 10px;
}



.main-container-wrapper .tweets-container .lottie {
    margin-left: -8vw;
}

.main-container-wrapper .tweets-container .tweets{
    /* margin: 0 30vw; */
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 10px;

    margin-top: 3vh;
    /* width: 100vw; */
}

.main-container-wrapper .tweets-container .tweets div{
    height: fit-content;
    /* width: 150vw; */
}

.main-container-wrapper .footer {
    margin-top: 10vh;
    margin-left: 8vw;
}

.main-container-wrapper .footer h2 {
    color: #FFFFFF;
    font-size: 4rem;
    text-align: left;
    font-family: Shadows Into Light;
    margin: 0;
}

/* .main-container-wrapper .footer p {
    color: white;
    text-align: left;
    margin-top: -10vh;
} */
.main-container-wrapper .footer .knowmore .then {
    /* position: relative; */
    /* text-align: center;
    width: 100vw;
    align-items: center;
    justify-content: center; */
    display: flex;
    align-items: center;
}
.main-container-wrapper .footer button {
    padding: 1px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: blue;
    border-radius: 10px;
}

.main-container-wrapper .footer svg {
    width: 2vw;
}

.main-container-wrapper .footer button p {
    width: 4vw;
    margin-left: 2px;
    color: #FFFFFF;
}

a {
    text-decoration:none;
    color:#FFF;
    /* position: absolute;
    bottom: 2.5vh;
    left: 10vw; */
  }

  .rainbow-button {
    width:calc(10vw + 6px);
    height:calc(4vw + 6px);
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
    border-radius:10px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-transform:uppercase;
    font-size: 2vw;
    font-weight:bold;
    margin-left: 20px;
  }
  .rainbow-button:after {
    content:'Mail me';
    width:10vw;
    height: 4vw;
    background-color:#191919;
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .rainbow-button:hover {
    animation:slidebg 2s linear infinite;
  }

  .main-container-wrapper .footer .line {
    display: flex;
    margin-left: -8vw;
    align-items: center;
    margin-top: 5vh;
  }
  .main-container-wrapper .footer .line a {
    margin-left: 0;
  }

  .main-container-wrapper .footer hr{
    width: 48vw;
    background-color: #3B82f6;
    border-radius: 2px;
    border: #3B82f6 1px solid;
  }

  .main-container-wrapper .footer .text{
    /* color: rgb(164, 164, 164); */
    color: #fff;
    display: flex;
    justify-content: center;
    margin-right: 8vw;
    /* margin-top: vh; */
    font-family: Inter;
    align-items: center;
  }
  .main-container-wrapper .footer .text a {
    margin-left: 0;
    color: #fff;
  }

  .main-container-wrapper .footer .text p {
    margin-top: 0;
  }
  /* .wavy {
    text-decoration-line: underline;
    text-decoration-style: wavy;
} */
  
  @keyframes slidebg {
    to {
      background-position:20vw;
    }
  }



@media only screen and (min-width:320px) and (max-width:700px) {

    .text-container {
        margin-top: 10vh;
    }
    .main-container {
        display: block;
    }
    .main-container .author {
        font-size: 4.5rem;
    }
    .main-container .subtitle {
        font-size: 1.2rem;
        margin-top: 10px;
    }

    

    .main-container .data {
        width: 80%;
        padding-top: 25vh;
    }

    .main-container .headshot-img img {
        /* display: none; */
        height: 50vh;
        border: none;
    }

    .main-container .headshot-img {
        padding-top: 0;
        width: 100%;
        /* height: 60%; */
        margin-top: 8vh;
        
    }


    .main-container .headshot-img .img-txt {
        padding-left: 8vw;
        /* text-align: center; */
    }


    .main-container .skills-container {
        margin-bottom: 0;
        margin-top: 13vh;
        width: 100%;
    }

    .main-container .skills-container .skills-slider{
        justify-content: left;
    }

    .skills-slider .list{
        margin-inline: 10px;
    }

    .main-container-wrapper .tweets-container {
        margin-top: 0;
    }
    .main-container-wrapper .tweets-container .tweets {
        display: block;
        margin-left: -8vw;
        /* width: 95vw; */
        height: 100%;
        padding-inline: 20px;
        /* margin-left: -5vw; */
    } 
    .main-container-wrapper .tweets-container .tweets .tweet{
        height: 100%;
    }

    .main-container-wrapper .tweets-container  .tweet-disable {
        display: none;
    }

    .main-container-wrapper .footer h2 {
        font-size: 2.5rem;
    } 

    .rainbow-button{
        width:calc(15vw + 8px);
        height:calc(7vw + 8px);
        font-size: 3vw;
        border-radius: 5px;
    }

    .rainbow-button:after {
        width: 15vw;
        height: 7vw;
        border-radius: 5px;
    }

    .main-container-wrapper .footer .line hr{
        width: 40vw;
    }

    .main-container-wrapper .footer .line svg {
        width: 40px;
    }

    .main-container-wrapper .footer .text {
        margin-top: 0;
    }
}

@media (min-width: 701px) and (max-width: 1000px){
    .main-container {
        display: block;
    }

    .main-container .headshot-img {
        width: 80%;
        margin: 15vh auto 0;
    }
    
    .main-container .skills-container {
        margin-bottom: 0;
        margin-top: 13vh;
        width: 100%;
    }
    .main-container-wrapper .tweets-container {
        margin-top: 0;
    }
    .main-container-wrapper .tweets-container  .tweet-disable {
        display: none;
    }
    .main-container-wrapper .tweets-container .tweets {
        /* grid-template-columns: 1fr 1fr; */
        display: block;
    }
}